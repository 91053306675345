<script setup>
import typeOf from 'just-typeof';

defineOptions({
    inheritAttrs: false,
});

const attrs = useAttrs();

defineProps({
    label: String,
    labelClass: String,
    wrapperClass: String,
    disclaimer: String,
    error: [String, Boolean],
    autocomplete: {
        type: String,
        default: 'off',
        // Doc - autocomplete attr.: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    },
});

/**
 * Handle number input.
 *
 * @param {string|number} value
 */
const handleNumber = (value) => {
    // if value is empty or not a string or number, return null
    if (
        value === '' ||
        (typeOf(value) !== 'string' && typeOf(value) !== 'number')
    ) {
        return null;
    }

    // if value is a string, convert it to a number
    if (typeOf(value) === 'string') {
        value = parseInt(value, 10);
    }

    // respect boundaries
    if (attrs.min) {
        value = Math.max(value, attrs.min);
    }
    if (attrs.max) {
        value = Math.min(value, attrs.max);
    }

    return value;
};

const emit = defineEmits(['on:blur']);

const [value, modifiers] = defineModel({
    type: [String, Number],
    set(value) {
        if (modifiers.number) {
            return handleNumber(value);
        }

        return value;
    },
});

const attributes = computed(() => {
    if (attrs.type === 'date' && !attrs.max) {
        return {
            ...attrs,
            max: '9999-12-31',
        };
    }

    return attrs;
});

function onBlur() {
    emit('on:blur', {
        text: value.value,
        name: attrs.name,
    });
}
</script>

<template>
    <div
        class="form-control-wrapper"
        :class="[{ 'has-error': error }, wrapperClass]"
    >
        <slot name="label">
            <label
                v-if="label"
                :class="['form-control-label', labelClass]"
                :for="attrs.id ?? attrs.name"
            >
                {{ label }}
            </label>
        </slot>
        <input
            v-bind="attributes"
            v-model="value"
            class="form-control"
            :autocomplete="autocomplete"
            @blur="onBlur"
        />
        <slot name="disclaimer">
            <span
                v-if="disclaimer"
                class="text-xs text-gray-600 mt-2 pr-6 leading-tight"
                v-text="disclaimer"
            />
        </slot>

        <InputErrorMessage
            :error="error"
            :field-label="label"
            :field-name="attrs.name"
        />
    </div>
</template>
